import React from "react"
import cx from "classnames"
import T from "prop-types"
import { compose, withState, withHandlers, setPropTypes } from "recompose"
import Img from "gatsby-image/withIEPolyfill"
import withTouch from "HOC/withTouch"
import styles from "./WorkshopItem.module.css"

export const WorkshopItem = ({
  id,
  price,
  title,
  images,
  followedX,
  description,
  materials,
  participants,
  duration,
  activeIndex,
  onNext,
  onPrev,
}) => (
  <article className={styles.workshop}>
    <h1 className={styles.title}>{title}</h1>
    <section className={styles.slider}>
      {(images || []).length > 1 ? (
        <button
          type="button"
          className={cx(styles.arrow, styles.left)}
          onClick={onPrev}
        >
          <svg viewBox="0 0 17 30">
            <path
              d="M16 1L2 15.824 16 29"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
            />
          </svg>
        </button>
      ) : null}
      <div className={styles.container}>
        <div
          className={cx(styles.wrapper, { [styles.drag]: Boolean(followedX) })}
          id={`workshop-slider-${id}`}
          style={{
            transform: `translateX(${activeIndex * 100 * -1 +
              followedX * -1}%)`,
            transition: followedX ? "none" : "",
          }}
        >
          {(images || []).filter(Boolean).map((image, i) => (
            <Img
              key={i}
              draggable={false}
              objectFit="cover"
              alt={title}
              title={title}
              objectPosition="50% 50%"
              className={styles.slide}
              fluid={image.childImageSharp.fluid}
              backgroundColor={(image.colors && image.colors.vibrant) || "#fff"}
            />
          ))}
        </div>
      </div>
      {(images || []).length > 1 ? (
        <button
          type="button"
          className={cx(styles.arrow, styles.right)}
          onClick={onNext}
        >
          <svg viewBox="0 0 17 30">
            <path
              d="M1 1l14 14.824L1 29"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
            />
          </svg>
        </button>
      ) : null}
    </section>
    <p className={styles.description}>{description}</p>
    <footer className={styles.footer}>
      <div className={styles.info}>Материалы: {materials}</div>
      <div className={styles.info}>Количество участников: {participants}</div>
      <div className={styles.info}>Продолжительность: {duration}</div>
      {price ? <div className={styles.info}>Cтоимость: {price}</div> : null}
    </footer>
  </article>
)

export const enhance = compose(
  withState("activeIndex", "setActiveIndex", 0),
  withHandlers({
    onNext: props => () =>
      props.setActiveIndex(
        props.activeIndex + 1 > props.images.length - 1
          ? 0
          : props.activeIndex + 1
      ),
    onPrev: props => () =>
      props.setActiveIndex(
        props.activeIndex - 1 < 0
          ? props.images.length + 1
          : props.activeIndex - 1
      ),
  }),
  setPropTypes({
    id: T.number.isRequired,
    title: T.string.isRequired,
    description: T.string.isRequired,
    images: T.arrayOf(
      T.shape({
        childImageSharp: T.shape({
          fluid: T.object.isRequired,
        }).isRequired,
      }).isRequired
    ).isRequired,
    materials: T.string.isRequired,
    participants: T.string.isRequired,
    duration: T.string.isRequired,
    activeIndex: T.number.isRequired,
    onNext: T.func.isRequired,
    onPrev: T.func.isRequired,
  }),
  withHandlers({
    onFollowTouchEnd: props => followedX =>
      followedX > 30 ? props.onNext() : followedX < -30 ? props.onPrev() : null,
  }),
  withTouch(props => `workshop-slider-${props.id}`)
)

export default enhance(WorkshopItem)
