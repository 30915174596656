import WorkshopsPage from "./WorkshopsPage"
import { graphql } from "gatsby"

export default WorkshopsPage

export const pageQuery = graphql`
  query WorkshopsPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "workshops-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        description
        items {
          title
          description
          images {
            colors {
              ...GatsbyImageColors
            }
            childImageSharp {
              fluid(maxHeight: 728, maxWidth: 1092, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          price
          duration
          materials
          participants
        }
      }
    }
  }
`
