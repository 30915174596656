import React from 'react'
import T from 'prop-types'
import * as M from 'Modules'
import WorkshopItem from './WorkshopItem'
import Markdown from 'react-markdown'
import SEO from 'Layout/SEO'
import styles from './WorkshopsPage.module.css'

export const WorkshopsPageTemplate = ({description, items}) => (
  <section className={styles.root}>
    <Markdown className={styles.text} source={description}/>

    <section className={styles.workshops}>
      {items.map((item, id) => <WorkshopItem key={id} id={id} {...item}/>)}
    </section>

    <M.ScrollUpButton/>
  </section>
)

WorkshopsPageTemplate.propTypes = {
  description: T.string.isRequired,
  items: T.arrayOf(T.object.isRequired).isRequired,
}

export const WorkshopsPage = props => (
  <>
    <SEO {...props.data.markdownRemark.frontmatter.seo} {...props}/>
    <WorkshopsPageTemplate
      items={props.data.markdownRemark.frontmatter.items}
      description={props.data.markdownRemark.frontmatter.description}
    />
  </>
)

WorkshopsPage.propTypes = {
  data: T.shape({
    markdownRemark: T.object.isRequired,
  }).isRequired,
}

export default WorkshopsPage
